body {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.body-container {
  position: relative;
}

.grid {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* width: 240;
  height: 240; */
  margin-bottom: 0;
}

.block {
  border: 1px solid black;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  text-align: center;
  font-size: 13px;
  width: 15px;
  height: 15px;
}
